import React, { useState, useMemo } from 'react'

import { WaterLevelPond } from '@jiaminghi/data-view-react'

import './WaterLevelChart.less'

var option = {
  data: [],
  shape: 'round',
  waveHeight: 25,
  waveNum: 2,
}

export default item => {
  const [currentDay, setCurrentDay] = useState(0)
  let getDay = new Date().getDay()
  if (currentDay != getDay) {
    if (item.type == 3) {
      option.data = [parseInt(Math.ceil(item.config[0].sum_rate / item.config[0].sum_count))]
    } else {
      let sumRate = 0
      let sumCount = 0
      item.config.map(res => {
        sumRate += parseInt(res.sum_rate)
        sumCount += parseInt(res.sum_count)
      })
      option.data = [parseInt(Math.ceil(sumRate / sumCount))]
    }
    setCurrentDay(new Date().getDay())
  }

  return useMemo(() => {
    console.log('又来更新一次')
    return (
      <div id="water-level-chart">
        <div className="chart-container">
          <WaterLevelPond config={option} />
        </div>
      </div>
    )
  }, [option])
}
