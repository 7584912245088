import React, { useState, useEffect } from 'react'

import { Charts, DigitalFlop, Decoration10 } from '@jiaminghi/data-view-react'

import WaterLevelChart from './WaterLevelChart'

import './Cards.less'

function getData(initData) {
  const items = initData.initData
  const titles = ['当前在线率', '公众号', '小程序', '昨日在线率', '七天在线率']
  const numbers = [
    parseInt(items.wireshark.active_count),
    parseInt(items.official.active_count),
    parseInt(items.applet.active_count),
  ]
  const nums = [
    parseInt(items.wireshark.sum_count - items.wireshark.active_count),
    parseInt(items.official.sum_count - items.official.active_count),
    parseInt(items.applet.sum_count - items.applet.active_count),
  ]
  const values = [
    parseInt(Math.ceil(items.wireshark.active_rate)),
    parseInt(Math.ceil(items.official.active_rate)),
    parseInt(Math.ceil(items.applet.active_rate)),
  ]
  return new Array(5).fill(0).map((foo, i) => ({
    title: titles[i],
    total: {
      number: [numbers[i]],
      content: '{nt}',
      textAlign: 'right',
      style: {
        fill: '#ea6027',
        fontWeight: 'bold',
      },
    },
    num: {
      number: [nums[i]],
      content: '{nt}',
      textAlign: 'right',
      style: {
        fill: '#26fcd8',
        fontWeight: 'bold',
      },
    },
    ring: {
      series: [
        {
          type: 'gauge',
          startAngle: -Math.PI / 2,
          endAngle: Math.PI * 1.5,
          arcLineWidth: 13,
          radius: '90%',
          data: [{ name: '在线比例', value: values[i] }],
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          pointer: {
            show: false,
          },
          backgroundArc: {
            style: {
              stroke: '#224590',
            },
          },
          details: {
            show: true,
            formatter: '在线率 {value}%',
            style: {
              fill: '#1ed3e5',
              fontSize: 25,
            },
          },
        },
      ],
      color: ['#03d3ec'],
    },
  }))
}

function randomExtend(minNum, maxNum) {
  if (arguments.length === 1) {
    return parseInt(Math.random() * minNum + 1, 10)
  } else {
    return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
  }
}

export default initData => {
  const [cards, setData] = useState([])
  const rateItems = initData.initData.rate || {}

  useEffect(() => {
    if (Object.keys(initData.initData).length === 0) {
      return
    }
    createData(initData)
  }, [initData])

  function createData(initData) {
    setData(getData(initData))
  }

  return (
    <div id="cards">
      {cards.map((card, i) => (
        <div className="card-item" key={card.title}>
          <div className="card-header">
            <div className="card-header-left">{card.title}</div>
            <div className="card-header-right">{'0' + (i + 1)}</div>
          </div>
          {i === 3 || i === 4 ? (
            <WaterLevelChart config={rateItems} type={i} />
          ) : (
            <>
              <Charts className="ring-charts" option={card.ring} />
              <div className="card-footer">
                <div className="card-footer-item">
                  <div className="footer-title">在线</div>
                  <div className="footer-detail">
                    <DigitalFlop config={card.total} style={{ width: '70%', height: '35px' }} />
                  </div>
                </div>
                <div className="card-footer-item">
                  <div className="footer-title">离线</div>
                  <div className="footer-detail">
                    <DigitalFlop config={card.num} style={{ width: '70%', height: '35px' }} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  )
}
