import React, { useState, useEffect } from 'react'

import { FullScreenContainer } from '@jiaminghi/data-view-react'

import TopHeader from './TopHeader'
import Cards from './Cards'
import LeftBar from './LeftBar'
import RightCard from './RightCard'

import './index.less'

function dataV() {
  const [init, setInit] = useState({})
  const [isRequst, setIsRequst] = useState(false)
  useEffect(() => {
    const getResult = async () => {
      const rest = await fetchUrl()
      setInit(rest)
    }
    getResult()
    const timer = setInterval(() => {
      setIsRequst(!isRequst)
    }, 300000)
    console.log('更新在线率')
    return () => {
      clearInterval(timer)
    }
  }, [isRequst])

  const fetchUrl = () => {
    return fetch('http://infrastructure-api.mokamrp.com/kanban/statistics')
      .then(res => res.json())
      .then(res => res)
  }

  return (
    <div id="data-view">
      <FullScreenContainer>
        <TopHeader />

        <div className="main-content">
          <div className="block-left-right-content">
            <div className="block-top-bottom-content">
              <Cards initData={init} />
              <div className="block-top-content">
                <LeftBar />
                <RightCard />
              </div>
            </div>
          </div>
        </div>
      </FullScreenContainer>
    </div>
  )
}

export default dataV
