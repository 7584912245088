import React, { useEffect, useState } from 'react'
import ReactECharts from 'echarts-for-react'

const option = {
  title: {
    text: '七日在线率趋势图',
    textStyle: { color: '#fff' },
    x: '2%', //左右距离
    y: '2%', //上下距离
    textStyle: {
      color: '#fff',
      fontWeight: 'bolder',
    },
  },
  tooltip: {
    trigger: 'axis',
  },
  legend: {
    data: ['手机脚本', '公众号', '小程序'],
    top: '5%',
    textStyle: {
      color: '#fff',
      fontSize: '16px',
    },
  },
  grid: {
    y: '16%', // y 偏移量
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: ['', '', '', '', '', '', ''],
    axisLabel: {
      show: true,
      interval: 0,
      rotate: 40,
      textStyle: {
        //   color: '#03d3ec',
        fontSize: '16px',
        interval: 0,
        rotate: 20,
      },
    },
  },
  yAxis: {
    type: 'value',
    min: 50,
    max: 300,
    offset: 30,
    // splitNumber: 10,
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dashed',
        color: 'rgb(4, 89, 141)',
      },
    },
    axisLabel: {
      show: false,
      textStyle: {
        color: '#fff',
        fontSize: 16,
      },
    },
  },
  series: [
    {
      name: '手机脚本',
      type: 'line',
      stack: '总量',
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        normal: {
          show: true,
          formatter: '{@value}%',
          position: 'top',
          textStyle: {
            color: '#03d3ec',
          },
          fontSize: '16px',
        },
      },
    },
    {
      name: '公众号',
      type: 'line',
      stack: '总量',
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        normal: {
          show: true,
          position: 'top',
          formatter: '{@value}%',
          textStyle: {
            color: '#03d3ec',
          },
          fontSize: '16px',
        },
      },
    },
    {
      name: '小程序',
      type: 'line',
      stack: '总量',
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        normal: {
          show: true,
          position: 'top',
          formatter: '{@value}%',
          textStyle: {
            color: '#03d3ec',
          },
          fontSize: '16px',
        },
      },
    },
  ],
}

//数据处理
const fetchUrl = () => {
  return fetch('http://infrastructure-api.mokamrp.com/kanban/seven-rates')
    .then(res => res.json())
    .then(res => res)
}

const RightCard = () => {
  const [day, setDay] = useState(0)
  var echartsReact
  useEffect(() => {
    setDay(new Date().getDay())
    const instance = echartsReact.getEchartsInstance()
    const result = async () => {
      const res = await fetchUrl()
      option.series[0].data = res.data.mobile
      option.series[1].data = res.data.wechat
      option.series[2].data = res.data.apple
      option.xAxis.data = res.data.date
      instance.setOption(option)
    }
    result()
    const timer = setInterval(() => {
      if (day != new Date().getDay()) {
        console.log(new Date().getDay())
        result()
      }
    }, 18000000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <ReactECharts
      option={option}
      ref={e => (echartsReact = e)}
      style={{
        height: '100%',
        width: '49.5%',
        marginLeft: '0.5%',
        backgroundColor: 'rgba(6, 30, 93, 0.5)',
      }}
      opts={{ renderer: 'svg' }}
    />
  )
}

export default RightCard
