import React, { useState, useEffect, Component } from 'react'
import ReactECharts from 'echarts-for-react'

var option = {
  title: {
    text: '机架实时柱状图',
    textStyle: { color: '#fff' },
    x: '2%', //左右距离
    y: '2%', //上下距离
    //   itemGap: 10, //间距
    textStyle: {
      color: '#fff',
      fontWeight: 'bolder',
    },
  },
  color: '#03d3ec',
  tooltip: {},
  legend: {
    data: ['在线数'],
  },
  xAxis: {
    name: '机架编号',
    nameTextStyle: { color: '#03d3ec', fontSize: '16px' },
    data: [
      '一号',
      '二号',
      '三号',
      '四号',
      '五号',
      '六号',
      '七号',
      '八号',
      '九号',
      '十号',
      '十一号',
      '十二号',
      '十三号',
    ],
    axisLabel: {
      show: true,
      interval: 0,
      rotate: 40,
      textStyle: {
        //   color: '#03d3ec',
        fontSize: '16px',
      },
    },
  },
  yAxis: {
    name: '在线数',
    nameLocation: 'middle',
    nameTextStyle: { color: '#03d3ec', fontSize: '16px', align: 'left' },
    type: 'value',
    min: 0,
    max: 84,
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dashed',
        color: 'rgb(4, 89, 141)',
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#fff',
        fontSize: '16px',
      },
    },
    //   #03d3ec
  },
  grid: {
    y: '16%', // y 偏移量
    x: '8%',
  },
  series: [
    {
      name: '',
      show: true,
      type: 'bar',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      showBackground: false,
      backgroundStyle: { color: 'rgba(180, 180, 180, 0.2)' },
      label: {
        normal: {
          show: true,
          position: 'top',
          textStyle: {
            color: '#03d3ec',
          },
          fontSize: '16px',
        },
        formatter: '{@value}',
      },
    },
  ],
}

//数据处理
const fetchUrl = () => {
  return fetch('http://infrastructure-api.mokamrp.com/kanban/frames')
    .then(res => res.json())
    .then(res => res)
}

const LeftBar = () => {
  var echartsReact
  useEffect(() => {
    const instance = echartsReact.getEchartsInstance()
    const result = async () => {
      const res = await fetchUrl()
      let items = []
      res.data.map(res => {
        items[res.show_area - 1] = res.sum_count
      })
      option.series[0].data = items
      instance.setOption(option)
    }
    result()
    const timer = setInterval(() => {
      result()
    }, 300000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <ReactECharts
      option={option}
      ref={e => (echartsReact = e)}
      style={{
        height: '100%',
        width: '49.5%',
        marginRight: '0.5%',
        backgroundColor: 'rgba(6, 30, 93, 0.5)',
      }}
      opts={{ renderer: 'svg' }}
    />
  )
}

export default LeftBar
